<template>
<div id="profile" class="container">
  <b-field horizontal class="is-centered">
    <button class="button is-primary" @click="onEditInfo">编辑信息</button>
    <button class="button is-primary" @click="onEditPass">修改密码</button>
  </b-field>
  <b-field horizontal label="ID" >
    {{user.uid}}
  </b-field>
  <b-field horizontal label="用户类型" >
    {{user.typename}}
  </b-field>
  <b-field horizontal label="ACCESSTOKEN" >
    <textarea class="textarea" disabled v-model="user.accesstoken" />
  </b-field>
  <b-field horizontal label="用户名" >
    <b-input name="uername" v-model.trim="userName" :disabled="editType!=='info'"></b-input>
  </b-field>
  <b-field horizontal label="昵称" >
    <b-input name="nickname" v-model.trim="nickName" :disabled="editType!=='info'"></b-input>
  </b-field>
  <b-field v-if="editType==='pass'" horizontal label="密码">
    <b-input name="oldPass" v-model.trim="oldPass" type="password" placeholder="旧密码"></b-input>
    <b-input name="newPass" v-model.trim="newPass" type="password" placeholder="新密码"></b-input>
    <b-input name="newPass2" v-model.trim="newPass2" type="password" placeholder="重复新密码"></b-input>
  </b-field>
  <b-field v-if="managedChannels.length>0" horizontal label="渠道" >
    <b-select multiple disabled native-size="3" v-model="managedChannels">
      <option v-for="channel in managedChannels"
        :value="channel.cid"
        :key="channel.cid">
        {{ channel.name }}
      </option>
    </b-select>
  </b-field>
  <b-field v-if="managedRegionals.length>0" horizontal label="区服" >
    <b-select multiple disabled native-size="3">
      <option v-for="regional in managedRegionals"
        :value="regional.rid"
        :key="regional.rid">
        {{ regional.name }}
      </option>
    </b-select>
  </b-field>
  <b-field v-if="editType!==null" horizontal>
    <button v-if="editType==='info'" class="button is-danger" @click="onSubmit">保存信息</button>
    <button v-else-if="editType==='pass'"
      class="button is-danger"
      :disabled="!canSavePass"
      @click="onSubmit">
      保存密码
    </button>
  </b-field>
</div>
</template>

<script>
import cache from '@/core/cache'

export default {
  name: 'profile',
  computed: {
    managedChannels: function () {
      return []
    },
    managedRegionals: function () {
      return []
    },
    canSavePass: function () {
      if (this.oldPass) {
        return this.newPass && this.newPass === this.newPass2
      }
      return false
    }
  },
  data: function () {
    const user = cache.getUser()
    return {
      user: user,
      editType: null,
      oldPass: '',
      newPass: '',
      newPass2: '',
      userName: user.username,
      nickName: user.nickname
    }
  },
  methods: {
    onEditInfo: function () {
      this.editType = 'info'
      this.oldPass = ''
      this.newPass = ''
      this.newPass2 = ''
    },
    onEditPass: function () {
      this.editType = 'pass'
      this.nickName = this.user.nickname
      this.userName = this.user.username
    },
    onSubmit: function () {
      if (this.editType === null) return
      this.hub.showProgress('请求中...')
      if (this.editType === 'info') {
        this.mjp.post('/admin/edit/',
          {
            idvalue: this.user.uid,
            idtype: 'adminuid',
            username: this.userName,
            nickname: this.nickName
          }).then(data => {
          this.editType = null
          // 更新修改后的账号信息
          cache.setUser(Object.assign(this.user, data.admin))
          this.user = cache.getUser()
          this.hub.showProgress(false)
          this.hub.alert('修改信息成功！')
        }).catch(err => {
          console.error(err)
        })
      } else if (this.editType === 'pass') {
        this.mjp.post('/admin/repass/',
          {
            idvalue: this.user.uid,
            idtype: 'adminuid',
            old: this.oldPass,
            new: this.newPass
          }).then(data => {
          this.editType = null
          this.hub.showProgress(false)
          this.hub.alert('修改密码成功！请重新登录。')
          this.hub.logout()
        }).catch(err => {
          console.error(err)
        })
      }
    }
  }
}
</script>

<style>

#profile {
  padding: 12px;
}

</style>
